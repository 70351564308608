import React from 'react'
import {Link} from 'gatsby'
import styles from './footer.module.css'

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.content}>
      <ul>
        <li>
          <h1>Resources</h1>
        </li>
        <li>
          <Link to="/faq">FAQ</Link>
        </li>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        <li>
          <Link to="/eula">EULA</Link>
        </li>
        <li>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </li>
      </ul>
      <ul>
        <h1>Contact</h1>
        <li>
          <a href="mailto:contact@staltz.com">Email</a>
        </li>
        <li>
          <a href="https://twitter.com/manyver_se">Twitter</a>
        </li>
        <li>
          <a href="https://fosstodon.org/@manyver_se/">Mastodon</a>
        </li>
      </ul>
      <ul>
        <li>
          <h1>Contribute</h1>
        </li>
        <li>
          <Link to="/develop">Develop</Link>
        </li>
        <li>
          <Link to="/design">Design</Link>
        </li>
        <li>
          <Link to="/translations">Translate</Link>
        </li>
        <li>
          <Link to="/donate">Donate</Link>
        </li>
      </ul>
      <ul>
        <li>
          <h1>Team</h1>
        </li>
        <li>
          <Link to="/team">Who we are</Link>
        </li>
        <li>
          <Link to="/roadmap">Roadmap</Link>
        </li>
      </ul>
    </div>
    <p>
      The Manyverse app is{' '}
      <a href="https://gitlab.com/staltz/manyverse">open source</a> software
      licensed as{' '}
      <a href="https://www.mozilla.org/en-US/MPL/">
        Mozilla Public License 2.0
      </a>{' '}
      and copyright by{' '}
      <a href="https://gitlab.com/staltz/manyverse/blob/master/AUTHORS">
        The Manyverse Authors
      </a>
      . The{' '}
      <a href="https://gitlab.com/staltz/manyverse-site">manyver.se website</a>{' '}
      is licensed as{' '}
      <a href="https://creativecommons.org/licenses/by-sa/4.0/">
        Creative Commons BY-SA 4.0
      </a>
      , copyright by <a href="https://staltz.com">Andre Staltz</a>.
    </p>
  </footer>
)

export default Footer
