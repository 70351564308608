import {Link} from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './header.module.css'

const Item = ({to, text}) => (
  <Link to={to} className={styles.item}>
    {text}
  </Link>
)

const Header = ({siteTitle}) => (
  <header className={styles.header}>
    <div className={styles.content}>
      <Item to={'/'} text="Home" />
      <Item to={'/blog'} text="Blog" />
      <Item to={'/contribute'} text="Contribute" />
      <Item to={'/download'} text="Download" />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
