import React from 'react'
import {Link} from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from './contribute.module.css'

function CodeIcon(color) {
  // https://pictogrammers.com/library/mdi/icon/code-braces/
  return (
    <svg className={styles.icon} viewBox={'0 0 24 24'}>
      <path
        fill={color}
        d="M8,3A2,2 0 0,0 6,5V9A2,2 0 0,1 4,11H3V13H4A2,2 0 0,1 6,15V19A2,2 0 0,0 8,21H10V19H8V14A2,2 0 0,0 6,12A2,2 0 0,0 8,10V5H10V3M16,3A2,2 0 0,1 18,5V9A2,2 0 0,0 20,11H21V13H20A2,2 0 0,0 18,15V19A2,2 0 0,1 16,21H14V19H16V14A2,2 0 0,1 18,12A2,2 0 0,1 16,10V5H14V3H16Z"
      />
    </svg>
  )
}

function ArtIcon(color) {
  // https://pictogrammers.com/library/mdi/icon/palette/
  return (
    <svg className={styles.icon} viewBox={'0 0 24 24'}>
      <path
        fill={color}
        d="M17.5,12A1.5,1.5 0 0,1 16,10.5A1.5,1.5 0 0,1 17.5,9A1.5,1.5 0 0,1 19,10.5A1.5,1.5 0 0,1 17.5,12M14.5,8A1.5,1.5 0 0,1 13,6.5A1.5,1.5 0 0,1 14.5,5A1.5,1.5 0 0,1 16,6.5A1.5,1.5 0 0,1 14.5,8M9.5,8A1.5,1.5 0 0,1 8,6.5A1.5,1.5 0 0,1 9.5,5A1.5,1.5 0 0,1 11,6.5A1.5,1.5 0 0,1 9.5,8M6.5,12A1.5,1.5 0 0,1 5,10.5A1.5,1.5 0 0,1 6.5,9A1.5,1.5 0 0,1 8,10.5A1.5,1.5 0 0,1 6.5,12M12,3A9,9 0 0,0 3,12A9,9 0 0,0 12,21A1.5,1.5 0 0,0 13.5,19.5C13.5,19.11 13.35,18.76 13.11,18.5C12.88,18.23 12.73,17.88 12.73,17.5A1.5,1.5 0 0,1 14.23,16H16A5,5 0 0,0 21,11C21,6.58 16.97,3 12,3Z"
      />
    </svg>
  )
}

function TranslateIcon(color) {
  // https://pictogrammers.com/library/mdi/icon/translate/
  return (
    <svg className={styles.icon} viewBox={'0 0 24 24'}>
      <path
        fill={color}
        d="M12.87,15.07L10.33,12.56L10.36,12.53C12.1,10.59 13.34,8.36 14.07,6H17V4H10V2H8V4H1V6H12.17C11.5,7.92 10.44,9.75 9,11.35C8.07,10.32 7.3,9.19 6.69,8H4.69C5.42,9.63 6.42,11.17 7.67,12.56L2.58,17.58L4,19L9,14L12.11,17.11L12.87,15.07M18.5,10H16.5L12,22H14L15.12,19H19.87L21,22H23L18.5,10M15.88,17L17.5,12.67L19.12,17H15.88Z"
      />
    </svg>
  )
}

function DonateIcon(color) {
  // https://pictogrammers.com/library/mdi/icon/hand-heart-outline/
  return (
    <svg className={styles.icon} viewBox={'0 0 24 24'}>
      <path
        fill={color}
        d="M16 3.23C16.71 2.41 17.61 2 18.7 2C19.61 2 20.37 2.33 21 3C21.63 3.67 21.96 4.43 22 5.3C22 6 21.67 6.81 21 7.76S19.68 9.5 19.03 10.15C18.38 10.79 17.37 11.74 16 13C14.61 11.74 13.59 10.79 12.94 10.15S11.63 8.71 10.97 7.76C10.31 6.81 10 6 10 5.3C10 4.39 10.32 3.63 10.97 3C11.62 2.37 12.4 2.04 13.31 2C14.38 2 15.27 2.41 16 3.23M22 19V20L14 22.5L7 20.56V22H1V11H8.97L15.13 13.3C16.25 13.72 17 14.8 17 16H19C20.66 16 22 17.34 22 19M5 20V13H3V20H5M19.9 18.57C19.74 18.24 19.39 18 19 18H13.65C13.11 18 12.58 17.92 12.07 17.75L9.69 16.96L10.32 15.06L12.7 15.85C13 15.95 15 16 15 16C15 15.63 14.77 15.3 14.43 15.17L8.61 13H7V18.5L13.97 20.41L19.9 18.57Z"
      />
    </svg>
  )
}

function Card(props) {
  const {label, icon, link, color} = props
  return (
    <Link to={link} className={styles.card}>
      <h2 style={{color}}>{label}</h2>
      {icon(color)}
      <span style={{color}} className={styles.readMore}>
        Read more →
      </span>
    </Link>
  )
}

export default function ContributePage() {
  return (
    <>
      <Layout>
        <SEO title="Contribute" />
        <div className={styles.container}>
          <h1 className={styles.title}>Contribute</h1>
          <p>
            It's easy to make Manyverse better, because it's all about small
            contributions that fit in your free time or your budget. And you can
            do it in many ways. Choose one of the four areas below and we will
            guide you step by step.
          </p>
          <p>Thank you in advance!</p>
        </div>

        <div className={styles.cardsContainer}>
          <Card
            label={'Develop'}
            icon={CodeIcon}
            link={'/develop'}
            color={'#4748ec'}
          />
          <Card
            label={'Design'}
            icon={ArtIcon}
            link={'/design'}
            color={'#fa5252'}
          />
          <Card
            label={'Translate'}
            icon={TranslateIcon}
            link={'/translations'}
            color={'#C26648'}
          />
          <Card
            label={'Donate'}
            icon={DonateIcon}
            link={'/donate'}
            color={'#12b886'}
          />
        </div>
        <div style={{marginTop: '6em'}} />
      </Layout>
    </>
  )
}
